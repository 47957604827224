import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#b12021',
        secondary: '#b12021',
        accent: '#b12021',
        error: '#b12021',
        info: '#b12021',
        success: '#b12021',
        warning: '#b12021',
      },
    },
  },
});
