import {
  GET_LIST,
  GET_MANY,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from "vuetify-admin/src/providers/data/actions";

import FetchJson from "./utils/fetchJson";
import qs from "qs";

export default (httpClient) => {
  if (typeof httpClient === "string") {
    httpClient = new FetchJson(httpClient);
  }

  return {
    [GET_LIST]: async (resource, params) => {
      // console.log(localStorage.getItem("jwt_token"));
      const { pagination, sort, filter } = params;

      let query = {
        filter: JSON.stringify(filter),
      };

      if (pagination) {
        let { page, perPage } = pagination;

        query = {
          ...query,
          range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        };
      }

      if (sort && sort.length) {
        query = {
          ...query,
          sort: JSON.stringify([sort[0].by, sort[0].desc ? "DESC" : "ASC"]),
        };
      }
      let { data } = await httpClient.get(
        `${resource}?${qs.stringify(query)}`
      );

      if (data.data.data) {
        data.data.data.map((o) => (o["id"] = o["uuid"]));
      } else if (data.data) {
        data.data.map((o) => (o["id"] = o["uuid"]));
      }
      return {
        data: data.data.data ? data.data.data : data.data,
        total: data.data.total,
      };
    },
    [GET_MANY]: (resource, params) => {
      httpClient.get(
        `${resource}?${qs.stringify({
          filter: JSON.stringify({ id: params.ids }),
        })}`
      );
    },
    [GET_ONE]: async (resource, params) => {
      let { data } = await httpClient.get(`${resource}/${params.id}`);

      return { data: data.data };
    },
    [CREATE]: (resource, params) => httpClient.post(resource, params.data),
    [UPDATE]: (resource, params) => {
      const updateData = updated_at_date(params.data);
      httpClient.put(`${resource}/${params.id}`, updateData);
    },
    [UPDATE_MANY]: (resource, params) =>
      Promise.all(
        params.ids.map((id) => {
          httpClient.put(`${resource}/${id}`, params.data);
        })
      ).then(() => Promise.resolve()),
    [DELETE]: (resource, params) => {
      httpClient.delete(`${resource}/${params.id}`);
    },

    [DELETE_MANY]: (resource, params) =>
      Promise.all(
        params.ids.map((id) => httpClient.delete(`${resource}/${id}`))
      ).then(() => Promise.resolve()),
  };
};

function updated_at_date(obj) {
  if (obj.created_at) {
    obj.created_at = obj.created_at.replace(/\./g, "-");
  }
  if (obj.planned_end_date) {
    obj.planned_end_date = obj.planned_end_date.replace(/\./g, "-");
  }
  if (obj.planned_start_date) {
    obj.planned_start_date = obj.planned_start_date.replace(/\./g, "-");
  }
  if (obj.updated_at) {
    obj.updated_at = obj.updated_at.replace(/\./g, "-");
  }
  return obj;
}
