import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";

import "vuetify-admin/src/loader";

import dataProvider from "@/providers/dataProvider";
import authProvider from "@/providers/authProvider";

import {} from "vuetify-admin/src/providers";
import { en } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_API_URL || "https://goldfish-app-giza3.ondigitalocean.app";
const fullAdminURL = `${baseURL}/api/admin`;

// const headers = () => {
//   let headers = new Headers({
//     // Accept: "application/json",
//   });

//   let token = localStorage.getItem("jwt_token");

//   if (token) {
//     headers.set("Authorization", `Bearer ${token}`);
//   }
//   return headers;
//   }

let token = localStorage.getItem("jwt_token");

const httpData = axios.create({
  baseURL: fullAdminURL,
  headers: { Authorization: `Bearer ${token}` },
});

httpData.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwt_token");
  const urlParams = new URLSearchParams(config.url);

  // filter tenders
  const filterTenderParam = urlParams.get('tenders?filter');
  if (filterTenderParam) {
    const valueFilterTenderParam = JSON.parse(filterTenderParam);
    if (valueFilterTenderParam.q) {
      config.url +=  `&name=${valueFilterTenderParam.q}`
    }
  }

  // filter users
  const usersParam = urlParams.get('users?filter');
  if (usersParam) {
    const valueFilterUsersParam = JSON.parse(usersParam);
    if (valueFilterUsersParam.q) {
      config.url +=  `&name=${valueFilterUsersParam.q}`
    }
  }

  // filter subtags
  const subtagsParam = urlParams.get('subtags?filter');
  if (subtagsParam) {
    const valueFilterSubtagsParam = JSON.parse(subtagsParam);
    if (valueFilterSubtagsParam.q) {
      config.url +=  `&name=${valueFilterSubtagsParam.q}`
    }
  }

  // pagination
  const paginParam = urlParams.get('range');
  if (paginParam) {
    const sizes = paginParam.substring(1, paginParam.length - 1).split(',').map((size) => Number(size))
    const limit = sizes[1] - sizes[0]
    let page = 1
    if (sizes[0] !== 0) {
      page = (sizes[0] / limit) + 1
    }
    config.url +=  `&page=${page}&limit=${limit}`
  }
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

const httpAuth = axios.create({
  baseURL,
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Application Admin",
  routes,
  locales: {
    en,
  },
  translations: ["en"],
  dataProvider: dataProvider(httpData),
  authProvider: authProvider(httpAuth),
  resources,
  httpData,
  options: {
    dateFormat: "long",
  },
});
