<template>
  <va-show-layout :title="title">
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="4">
          <v-card>
            <v-card-text>
              <va-field source="applicationId" type="number"></va-field>
              <va-field source="name"></va-field>
              <va-field source="customerId" type="number"></va-field>
              <va-field source="detailText"></va-field>
              <va-field source="planned_start_date" type="date"></va-field>
              <va-field source="planned_end_date" type="date"></va-field>
              <va-field source="statusId" type="number"></va-field>
              <va-field source="address"></va-field>
              <va-field source="winnerId"></va-field>
              <va-field source="created_at" type="date"></va-field>
              <va-field source="updated_at" type="date"></va-field>
              <va-field source="uuid"></va-field>
              <va-field source="planned_start_price"></va-field>
              <va-field source="planned_end_price"></va-field>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
  methods: {
    show() {
    },
  },
};
</script>
