<template>
  <v-card>
    <v-card-title>
      <h1 class="display-1">
        {{ title }}
      </h1>
    </v-card-title>
    <v-card-text>
      <va-list :filters="filters">
        <va-data-table :fields="fields"></va-data-table>
      </va-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      // filters: [
      //   {
      //     source: "customerId",
      //     type: "select",
      //     alwaysOn: true,
      //     attributes: { reference: "users" },
      //   },
      // ],
      fields: [
        { source: "applicationId", type: "number" },
        "name",
        { source: "customerId", type: "number" },
        "detailText",
        { source: "planned_start_date", type: "string" },
        { source: "planned_end_date", type: "date" },
        { source: "statusId", type: "number" },
        "address",
        "winnerId",
        { source: "created_at", type: "date" },
        { source: "updated_at", type: "date" },
        "uuid",
        "planned_start_price",
        "planned_end_price",
      ],
    };
  },
};
</script>
