<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list>
      <va-data-table :fields="fields"></va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "phone", link: "show" },
        { source: "type", type: "select" },
        "companyName",
        "middlename",
        "surname",
        "workExperience",
        { source: "email", type: "email" },
        { source: "image", type: "image" },
        { source: "description", type: "text" },
        { source: "created_at", type: "date" },
        { source: "updated_at", type: "date" },
      ],
    };
  },
};
</script>
