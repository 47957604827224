export default [
  {
    name: "users",
    icon: "mdi-account",
    // label: "name",
    // permissions: ["admin"],
  },
  {
    name: "tenders",
    icon: "mdi-post",
    //   label: "name",
    //   translatable: true,
    //   permissions: ["admin", "parent"],
  },
  {
    name: "subtags",
    icon: "mdi-tag",
    //   label: "name",
    //   translatable: true,
    //   permissions: ["admin", "parent"],
  },
];
