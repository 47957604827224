<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="4">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-1">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="name"></va-field>
              <va-field source="surname"></va-field>
              <va-field source="middlename"></va-field>
              <va-field source="companyName"></va-field>

              <va-field source="image"></va-field>

              <va-field source="description"></va-field>
              <va-field source="created_at" type="date"></va-field>
              <va-field source="portfolios"></va-field>

              <va-field source="reviews"></va-field>
              <va-field source="roles"></va-field>
              <va-field source="subtags"></va-field>

              <va-field source="updated_at"></va-field>
              <va-field source="uuid"></va-field>
              <va-field source="workExperience"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
