
<template>
  <va-edit-layout :title="title">
    <va-form :id="id" :item="item">
      <v-row justify="center">
        <v-col sm="4">
          <v-card>
            <v-card-text>
              <va-text-input source="name"></va-text-input>
              <va-text-input source="surname"></va-text-input>
              <va-text-input source="middlename"></va-text-input>
              <va-text-input source="email"></va-text-input>
              <va-date-input source="created_at"></va-date-input>
              <va-date-input source="updated_at"></va-date-input>
              <va-text-input source="workExperience"></va-text-input>
              <va-text-input source="uuid"></va-text-input>
              <va-text-input source="description"></va-text-input>
              <va-save-button></va-save-button>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </va-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>