<template>
  <va-create-layout :title="title">
    <va-form :item="item">
      <v-row justify="center">
        <v-col sm="4">
          <v-card>
            <v-card-text>
              <va-number-input source="applicationId"></va-number-input>
              <va-text-input source="name"></va-text-input>
              <va-number-input source="customerId"></va-number-input>
              <va-text-input source="detailText"></va-text-input>
              <va-date-input source="planned_start_date"></va-date-input>
              <va-date-input source="planned_end_date"></va-date-input>
              <va-number-input source="statusId"></va-number-input>
              <va-text-input source="address"></va-text-input>
              <va-text-input source="winnerId"></va-text-input>
              <va-date-input source="created_at"></va-date-input>
              <va-date-input source="updated_at"></va-date-input>
              <va-text-input source="uuid"></va-text-input>
              <va-text-input source="planned_start_price"></va-text-input>
              <va-text-input source="planned_end_price"></va-text-input>
              <va-save-button></va-save-button>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </va-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>